import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import LocationsSvg from "../../components/svg/Locations";

export default function Locations({ data }) {
  const page = data.wpPage;
  return (
    <Layout>
      <Seo seo={page.seo} />

      <div className="profile__heading__wrapper mt-6">
        <h3 className="profile__heading text-blue">{page.title}</h3>
      </div>

      {page.content && (
        <div className="wysiwyg__content__wrapper pt-6 pb-20">
          <div
            className="wysiwyg__content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
      )}
      <div className="profile__heading__wrapper">
        <LocationsSvg />
      </div>
      <div className="mb-20" />
    </Layout>
  );
}

export const query = graphql`
  {
    wpPage(slug: { eq: "presence" }) {
      title
      content
      seo {
        fullHead
      }
    }
  }
`;
